import request from '@/utils/request'

export function getAdConfig (data) {
    return request({
        url: '/small-program-shd/lrAd/queryLrAd',
        method: 'post',
        data,
        
    })
}

export function saveAdConfig (data) {
    return request({
        url: '/shd-operation/lrAd/addLrAd',
        method: 'post',
        data,
        
    })
}